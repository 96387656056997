// Translated
// Migrated
<template lang="pug">
img(:src="iconUrl")
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  name: 'CampaignIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      default: 'white',
    },

    size: {
      type: Number,
      default: 24,
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['isCharter']),

    iconUrl () {
      return formatCloudinaryImagePath(this.icon, {
        transformation: { custom: `h_${this.size},e_colorize,co_${this.color}` },
        isCharter: this.isCharter
      })
    }
  }
})
</script>
